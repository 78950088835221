import classNames from 'classnames'
import Link from 'next/link'
import { MouseEvent, MouseEventHandler, useContext, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { ASvg } from 'ui'
import { FormData, MFormSearch } from 'ui/components/molecules/m-form-search'
import SvgLoadingIcon from 'ui/svg/icons/stroke/loading.svg'
import SvgCheckFalseIcon from 'ui/svg/icons/fill/check-false.svg'
import SvgCheckTrueIcon from 'ui/svg/icons/fill/check-true.svg'
import SvgCheckIcon from 'ui/svg/icons/fill/check.svg'
import SvgErrorIcon from 'ui/svg/icons/fill/error.svg'
import SvgMailIcon from 'ui/svg/icons/fill/mail.svg'
import SvgFacebookIcon from 'ui/svg/icons/social/Facebook.svg'
import SvgInstagramIcon from 'ui/svg/icons/social/Instagram.svg'
import SvgYoutubeIcon from 'ui/svg/icons/social/Youtube.svg'
import SvgVimeoIcon from 'ui/svg/icons/social/Vimeo.svg'
import { HeaderContext } from 'ui/context/header'
import TagManager from 'react-gtm-module'
import './c-the-footer.css'
import { getIsTestEmail, useSiteInfo } from 'shared'

type Props = {
  hasNewsletter: boolean
  hasExtraBelow?: boolean
  hasExtraInline?: boolean
  hasStaarLink?: boolean
  iclBaseUrl?: string
  fullDomain?: string
  discoveryUrl?: string
}

const defaultProps = {
  hasExtraBelow: false,
  hasExtraInline: false,
  hasStaarLink: false,
  backendUrl: '',
  fullDomain: '',
}

type CustomLinkProps = {
  className?: string
  href?: string
  onClick?: MouseEventHandler
  children?: any
  target?: string
}

const CustomLink = ({
  className,
  href,
  onClick,
  children,
  target,
}: CustomLinkProps) => {
  if (/^http/.test(href ?? '')) {
    return (
      <a className={className} href={href} onClick={onClick} target={target}>
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={className}
        href={href ?? ''}
        onClick={onClick}
        prefetch={false}
        target={target}
      >
        {children}
      </Link>
    )
  }
}

const CTheFooter = (props: Props) => {
  const siteInfo = useSiteInfo()
  const {t} = useTranslation('common')
  const headerContext = useContext(HeaderContext)

  const [formError, setFormError] = useState<boolean>(false)
  const [formLoading, setFormLoading] = useState<boolean>(false)
  const [formSuccess, setFormSuccess] = useState<boolean>(false)

  const [formConfirmed, setFormConfirmed] = useState<boolean>(false)
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

  const handleOpenLocaleSwitcher = (e: MouseEvent) => {
    e.preventDefault()
    headerContext?.setShowLocaleSwitcher(true)
  }

  const submitFormEmail = (data: FormData) => {
    setFormSubmitted(true)
    const isTestEmail = getIsTestEmail(data.q)

    if (
      formLoading ||
      formSuccess ||
      (!formConfirmed && props.hasNewsletter) ||
      isTestEmail
    ) {
      return
    }

    setFormLoading(true)

    const body = {
      contact: {
        Email: data.q,
      },
      trigger: '0008',
    }

    fetch(
      process.env.NEXT_PUBLIC_DISCOVERICL_API_PROXY_URL +
      '/autopilot/captureUser.js',
      {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not OK')
        }

        setFormSuccess(true)
        setFormLoading(false)

        TagManager.dataLayer({
          dataLayer: {
            event: 'emailSubmissions',
            event_category: 'Email Submissions',
            event_action: 'Success',
            event_label: window.location.href,
          },
        })
      })
      .catch(() => {
        setFormError(true)
        setFormLoading(false)
      })
  }

  return (
    <footer className="c-the-footer bg-neutral-black text-neutral-white">
      <div
        className="flex flex-wrap items-center justify-center px-6 py-10 lg:justify-between xl:px-10 c-the-footer__container relative">
        <ul
          className="flex-wrap items-center justify-center hidden w-full font-semibold text-center space-i-8 md:flex xl:space-i-14 text-p-sm c-the-footer__menu-list text-neutral-gray lg:w-3/5 lg:justify-start">
          {!siteInfo.isES && (
            <>
              {t('paths.life-with-visian') && !siteInfo?.isNL && !siteInfo.isIN &&  (
                <li className="c-the-footer__menu-item">
                  <CustomLink
                    className="transition duration-300 hover:text-neutral-gray"
                    href={`${props.discoveryUrl}${t('paths.life-with-visian')}`}
                  >
                    <span>{t('titles.life-with-visian')}</span>
                  </CustomLink>
                </li>
              )}
              {t('paths.safety-information') && (
                <li className="c-the-footer__menu-item">
                  <CustomLink
                    className="transition duration-300 hover:text-neutral-gray"
                    href={`${props.discoveryUrl}${t(
                      'paths.safety-information'
                    )}`}
                  >
                    <span>{t('titles.safety-information')}</span>
                  </CustomLink>
                </li>
              )}
              {t('paths.blog') && (
                <li className="c-the-footer__menu-item">
                  <CustomLink
                    className="transition duration-300 hover:text-neutral-gray"
                    href={`${props.discoveryUrl}${t('paths.blog')}`}
                  >
                    <span>{t('titles.visian-icl-blog')}</span>
                  </CustomLink>
                </li>
              )}
            </>
          )}
          {t('paths.contact') && (
            <li className="c-the-footer__menu-item">
              <CustomLink
                className="transition duration-300 hover:text-neutral-gray"
                href={`${props.discoveryUrl}${t('paths.contact')}`}
              >
                <span>{t('titles.contact')}</span>
              </CustomLink>
            </li>
          )}
          {props.hasStaarLink && (
            <li className="c-the-footer__menu-item">
              <a
                className="transition duration-300 hover:text-neutral-gray"
                href="https://staar.co.jp/sp/company/"
              >
                <span>会社概要</span>
              </a>
            </li>
          )}
        </ul>
        {!siteInfo.isPL && !siteInfo.isES && (
          <ul
            className="flex items-center justify-center w-full space-i-12 md:mt-12 lg:w-1/5 lg:justify-end lg:space-i-5 c-the-footer__socials-list lg:mt-0 lg:pis-4">
            {t('urls.facebook') && (
              <li>
                <a
                  className="block transition duration-300 text-primary-gray hover:text-neutral-gray"
                  href={t('urls.facebook') ?? ''}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ASvg
                    className="w-6 h-6 fill-current"
                    svg={SvgFacebookIcon}
                    aria-label={`Facebook ${t('icon')}`}
                  />
                </a>
              </li>
            )}
            {t('urls.instagram') && (
              <li>
                <a
                  className="block transition duration-300 text-primary-gray hover:text-neutral-gray"
                  href={t('urls.instagram') ?? ''}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ASvg
                    className="w-6 h-6 fill-current"
                    svg={SvgInstagramIcon}
                    aria-label={`instagram ${t('icon')}`}
                  />
                </a>
              </li>
            )}
            {t('urls.youtube') && (
              <li>
                <a
                  className="block transition duration-300 text-primary-gray hover:text-neutral-gray"
                  href={t('urls.youtube') ?? ''}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ASvg
                    className="w-6 h-6 fill-current"
                    svg={SvgYoutubeIcon}
                    aria-label={`Youtube ${t('icon')}`}
                  />
                </a>
              </li>
            )}
            {t('urls.vimeo') && (
              <li>
                <a
                  className="block transition duration-300 text-primary-gray hover:text-neutral-gray"
                  href={t('urls.vimeo') ?? ''}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ASvg
                    className="w-6 h-6 fill-current"
                    svg={SvgVimeoIcon}
                    aria-label={`Vimeo ${t('icon')}`}
                  />
                </a>
              </li>
            )}
          </ul>
        )}
        {props.hasExtraInline && (
          <div className="w-full c-the-footer__extra break-all">
            <div>
              <p className="text-button">
                * 이보ICL의 제품명은 EVO(+) VisianICL(VICL), EVO(+)
                VisianToricICL(VTICL)입니다.
                <br/>
                References 1. Mark Packer. The EVO ICL for Moderate Myopia:
                Results from the US FDA Clinical Trial. Clinical Ophthalmology.
                2022. 3981-3991. 2. Ganesh, Sri et al. Matched population
                comparison of visual outcomes and patient satisfaction between 3
                modalities for the correction of low to moderate myopic
                astigmatism. Clinical ophthalmology 2017:11 1253–1263. 3. FDA.
                Before, During& After Surgery.
                https://www.fda.gov/medical-devices/phakic-intraocular-lenses/during-after-surgery
                (as of 2021-12-24) 4. 윤재문외.
                난시교정용후방유수정체안내렌즈삽입술의단기간임상성적.
                대한안과학회지. 2009;50(6):839-851. 5. Akihito Igarashi.
                Posterior chamber phakic IOLs vs. LASIK: benefits and
                complications. Expert Review ofOphthalmology. 2019:14 43–52. 6.
                U.S. Food and Drug Administration, Center for Devices and
                Radiological Health. Approval Letter, March 25, 2022. FDA.
                https://www.accessdata.fda.gov/cdrh_docs/pdf3/P030016S035A.pdf.
                7. Packer M. Evaluation of the EVO/EVO+ Sphere and
                ToricVisianICL: Six Month Results from the United States Food
                and Drug Administration Clinical Trial. Clinical Ophthalmology.
                2022;16:1541-53. 8. Feingold et al. Biocompatible, optically
                transparent, ultraviolet light absorbing, polymeric material
                based upon collagen and method of making. USPTO June 8, 1999. 9.
                Gregory D. Parkhurst, et al. Phakic Intraocular Lens
                Implantation in United States Military Warfighters: A
                Retrospective Analysis of Early Clinical Outcomes of the EVO
                ICL. J Refract Surg. 2011;27(7);473-48. 10. Steven Schallhorn,
                MD et al. Night Driving Simulation in a Randomized Prospective
                Comparison of VisianToricImplantable CollamerLenses and
                Conventional PRK for Moderate to High Myopic Astigmatism.
                Journal of Refractive Surgery Vol.26, No. 5, 2010:321-326. 11.
                Gregory D Parkhurst. A prospective comparison of phakic collamer
                lenses and wavefront-optimized laser-assisted in situ
                keratomileusis for correction of myopia. Clinical Ophthalmology
                2016;10:1209-1215.
                <br/>
                <br/>
                ※ 이 제품은 ‘의료기기-유수정체인공수정체’이며, 수술을 원하실
                경우 안과에 방문하셔서 추가 주의사항 및 수술 관련 내용을
                확인하십시오.
                <br/>
                광고심의필: 심의번호 32023-|10-04-0326 (유효기간: 2026.02.10)
                <br/>
                광고심의필: 조합-2022-04-029 l 조합-2022-04-034 l
                조합-2022-04-035 (유효기간: 2025.02.03)
                <br/>
                광고심의필: 조합-2022-04-030 l 조합-2022-04-031 l
                조합-2022-04-032 (유효기간: 2025.02.07) | 광고심의필:
                조합-2022-04-033 (유효기간: 2025.02.08)
              </p>
            </div>
          </div>
        )}

        <div className="w-full mt-12 lg:order-1 c-the-footer__newsletter lg-mt-8 lg:w-2/5 lg:pis-4">
          {props.hasNewsletter && (
            <>
              <h4 className="mb-4 font-bold text-center lg:hidden text-p text-primary-gray">
                {t('stay-updated-on-visian-icl')}
              </h4>
              <div className="text-primary-gray">
                <MFormSearch
                  icon={
                    <>
                      {!formSuccess && !formLoading && !formError && (
                        <ASvg
                          aria-label={t('mail')}
                          className="w-8 h-8 duration-200 ease-out fill-current hover:text-primary-teal animate-opacity"
                          svg={SvgMailIcon}
                        />
                      )}
                      {formLoading && !formError && (
                        <ASvg
                          aria-label={t('mail')}
                          className="w-6 h-6 fill-current c-the-footer__loading-icon"
                          svg={SvgLoadingIcon}
                        />
                      )}
                      {formSuccess && (
                        <ASvg
                          aria-label={t('success')}
                          className="w-8 h-8 fill-current"
                          svg={SvgCheckIcon}
                        />
                      )}
                      {formError && (
                        <ASvg
                          aria-label={t('error')}
                          className="w-8 h-8 fill-current"
                          svg={SvgErrorIcon}
                        />
                      )}
                    </>
                  }
                  text={t('enter-your-email-to-stay-updated') || ''}
                  type="email"
                  errorMessage={
                    (formError &&
                      t('there-was-a-problem-with-your-email-address')) ||
                    ''
                  }
                  successMessage={
                    (formSuccess && t('thanks-for-subscribing')) || ''
                  }
                  onSubmit={submitFormEmail}
                />
                <button
                  className="cursor-pointer flex mx-4 lg:mx-0 p-5 mt-3"
                  onClick={() => setFormConfirmed(!formConfirmed)}
                >
                  <ASvg
                    className={classNames(
                      'flex-shrink-0 fill-current mr-4 text-primary-gray w-6 h-6',
                      {'text-secondary-red': !formConfirmed && formSubmitted}
                    )}
                    svg={formConfirmed ? SvgCheckTrueIcon : SvgCheckFalseIcon}
                  />
                  <div>
                    <p className="text-[0.625rem] text-left text-primary-gray w-full mt-0">
                      {t('form-subscription-text')}
                    </p>
                    {!formConfirmed && formSubmitted && (
                      <p className="text-xs font-bold text-secondary-red mt-1">
                        Checkbox is required.
                      </p>
                    )}
                  </div>
                </button>
              </div>
            </>
          )}
          <div className="c-the-footer__one-trust flex justify-center md:justify-end">
            <button
              id="ot-sdk-btn"
              className="ot-sdk-show-settings !border-0 !text-primary-gray hover:!bg-transparent underline hover:!text-neutral-gray"
            >
              Cookie Settings
            </button>
          </div>
        </div>
        <div
          className={`flex-wrap items-center w-full pt-8 mt-16 lg:mt-10 font-semibold tracking-wider border-t md:flex border-neutral-dark-gray text-p-sm lg:w-3/5 c-the-footer__note lg:pt-0 lg:border-t-0 xl:flex`}>
          <p
            className="pt-2 text-center uppercase c-the-footer__copy text-primary-gray lg:text-start xl:pie-2 xl:pt-2 md:mie-4">
            &copy;&nbsp;
            {new Date().getFullYear()}
            <CustomLink
              className="text-neutral-white lg:text-primary-gray"
              href={`${props.discoveryUrl}/`}
            >
              &nbsp;STAAR Surgical®.&nbsp;
            </CustomLink>
            {t('all-rights-reserved')}
            {siteInfo.isIN && (
              <span className='inline-block ml-1'>
                IN-EVO ICL-24-0058
              </span>
            )}
          </p>
          <ul
            className="flex flex-wrap items-center justify-center pt-2 text-center space-i-2 lg:justify-start text-neutral-white lg:text-primary-gray c-the-footer__links-list">
            {t('paths.privacy-policy') && (
              <li className="c-the-footer__links-item">
                <CustomLink
                  className="transition duration-300 hover:text-neutral-gray"
                  href={`${props.discoveryUrl}${t('paths.privacy-policy')}`}
                >
                  <span>{t('titles.privacy-policy')}</span>
                </CustomLink>
              </li>
            )}
            {t('paths.terms-of-use') && (
              <li className="c-the-footer__links-item">
                <CustomLink
                  className="transition duration-300 hover:text-neutral-gray"
                  href={`${props.discoveryUrl}${t('paths.terms-of-use')}`}
                >
                  <span>{t('titles.terms-of-use')}</span>
                </CustomLink>
              </li>
            )}
            <li className="c-the-footer__links-item">
              <a
                className="font-semibold tracking-wider uppercase transition duration-300 cursor-pointer hover:text-neutral-gray"
                onClick={handleOpenLocaleSwitcher}
              >
                <span>{t('international-sites')}</span>
              </a>
            </li>
          </ul>
          {props.hasExtraBelow && (
            <div className="c-the-footer__extra">
            <div>
          <p>
            Die STAAR Surgical® AG Niederlassung Deutschland mit Sitz in
            Hamburg ist eine Zweigniederlassung der STAAR Surgical® AG mit
            Hauptsitz in Nidau in der Schweiz.
          </p>

          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            <p>
              Vertreter der STAAR Surgical® AG:
              <br/>
              Philippe Subrin
              <br/>
              Stefan Blaser
              <br/>
              Patrick Forrest Williams
              <br/>
              Alexandre Berger
            </p>
            <p>
              Postanschrift der STAAR Surgical® AG:
              <br/>
              Hauptstraße 104
              <br/>
              CH-2560 Nidau, Schweiz
              <br/>
              Firmennummer: CHE-107.597.166
            </p>
            <p>
              Postanschrift der STAAR Surgical® AG Niederlassung
              Deutschland:
              <br/>
              Harksheider Strasse 3<br/>
              22399 Hamburg
              <br/>
              Tel.: <a href="tel:+494052472520">+49 40 524 72 52 0</a>
              <br/>
              Fax: +49 40 524 72 52 99
            </p>
            <p>
              Kundenbetreuung:
              <br/>
              E-Mail: icl.deutschland@staar.com
              <br/>
              Tel.: <a href="tel:+494052472520">+49 40 524 72 52 0</a>
            </p>
          </div>
          <p>
            Die STAAR Surgical® AG Niederlassung Deutschland ist im
            Handelsregister des Amtsgerichts Hamburg unter der Nummer HRB
            155116 eingetragen.
          </p>
          <p>
            Die Umsatzsteueridentifikationsnummer der STAAR Surgical® AG
            Niederlassung Deutschland ist DE 299761720.
          </p>
        </div>
      </div>
    )}
  </div>
</div>
</footer>
)
}

CTheFooter.defaultProps = defaultProps

export {CTheFooter}
