import { useState, useMemo } from 'react'
import classNames from 'classnames'
import {
  CraftAssetField,
  CraftEmbeddedAssetField,
  CraftLinkItField,
} from 'tsconfig/craft-types'
import './c-procedure-steps.css'
import { APicture, MVideoThumbnail, AButton } from 'ui'
import { motion } from 'framer-motion'
import SvgArrowUpIcon from 'ui/svg/icons/stroke/arrow-up.svg'
import SvgArrowDownIcon from 'ui/svg/icons/stroke/arrow-down.svg'

export type CProcedureStepsBlock = {
  id?: string
  step: Array<StepBlockType>
}

type StepBlockType = {
  id: string
  subheading: string
  heading: string
  imageDesktop: Array<CraftAssetField>
  imageMobile: Array<CraftAssetField>
  video: Array<CraftEmbeddedAssetField>
  description: string
  button1: CraftLinkItField
  button2: CraftLinkItField
  stepperTitle: string
}

type Props = {
  field: Array<CProcedureStepsBlock>
}

export const CProcedureSteps = ({ field }: Props) => {
  const [activeStep, setActiveStep] = useState(0)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [showSticky, setShowSticky] = useState(false)

  const content = useMemo(() => {
    return field && field.length
      ? {
          ...field[0],
        }
      : null
  }, [field])
  const message = ['Online', 'At home', 'In person', 'In person', 'At home']

  return (
    <section id={content?.id} className="my-3 c-procedure-steps md:my-6">
      <ol className="c-stepper">
        {content?.step?.map((step, i) => {
          const message = ['Online', '', 'In person', '', 'At home']
          return (
            <li className="c-stepper__item">
              {i % 2 === 0 && (
                <div className="c-stepper__messageBox text-neutral-white">
                  <p>Step {i + 1}</p>
                  <p>{message[i]}</p>
                  <div className="c-stepper__messageBox-pointer" />
                </div>
              )}
              <div className="c-stepper__content">
                <h3 className="c-stepper__title">{step.stepperTitle}</h3>
                <span className="c-stepper__number">{i + 1}</span>
              </div>
            </li>
          )
        })}
      </ol>
      <ol className="c-timeline mt-10 relative">
        {content?.step?.map((step, i) => {
          return (
            <li className="c-timeline__item">
              {i % 2 === 0 && (
                <div className="c-timeline__messageBox text-neutral-white">
                  <p>Step {i + 1}</p>
                  <p>{message[i]}</p>
                  <div className="c-timeline__messageBox-pointer" />
                </div>
              )}
              <div className="c-timeline__content">
                <h3 className="c-timeline__title">{step.stepperTitle}</h3>
                <span className="c-timeline__number">{i + 1}</span>
              </div>
            </li>
          )
        })}
      </ol>
      <motion.div
        onViewportEnter={() => setShowSticky(true)}
        onViewportLeave={() => setShowSticky(false)}
      >
        {content?.step?.map((step, i) => {
          const isMp4 = Boolean(
            step.video?.[0] && !step.video?.[0]?.embeddedAsset
          )
          const isOdd = Boolean(i % 2 !== 0)

          return (
            <div
              className={classNames('procedure-steps__step flex', {
                'bg-blue-wave': isOdd,
              })}
            >
              <motion.div
                onViewportEnter={() => {
                  setActiveStep(i)
                  setShowSticky(true)
                }}
                onViewportLeave={() => {
                  if (activeStep === content?.step?.length - 1)
                    setShowSticky(false)
                }}
              >
                <div
                  className={classNames(
                    'relative c-procedure-steps__mobile md:mx-6 '
                  )}
                >
                  {step.subheading && (
                    <h3
                      className={classNames(
                        'mb-3 uppercase font-semibold text-h8',
                        {
                          'text-neutral-white': isOdd,
                          'text-primary-teal': !isOdd,
                        }
                      )}
                    >
                      {step.subheading}
                    </h3>
                  )}
                  {step.heading && (
                    <h1
                      className={classNames('text-3xl mb-5', {
                        'text-neutral-white': isOdd,
                      })}
                    >
                      {step.heading}
                    </h1>
                  )}
                </div>
              </motion.div>
              <div className="procedure-steps__step--background-image">
                {step?.imageDesktop?.[0] && !step?.video?.[0] && (
                  <APicture
                    className="w-full"
                    imageDesktop={step?.imageDesktop?.[0]}
                    imageMobile={step?.imageMobile?.[0]}
                    mediaQuery="(max-width: 767px)"
                  />
                )}
                {step?.video?.[0]?.url && !isMp4 && (
                  <div
                    className={`c-procedure-steps-wrapper flex-1 $aspect-[3/4] md:w-[305px] md:h-[100%] max-w-[100%] mx-auto`}
                  >
                    <MVideoThumbnail
                      className={`$aspect-[3/4] w-fullmd:w-[305px] md:h-[545px] max-w-[100%] h-full items-center flex`}
                      video={step?.video}
                      thumbnail={step.imageDesktop?.[0]?.url}
                    />
                  </div>
                )}
                {step?.video?.[0]?.url && isMp4 && (
                  <div
                    className={`c-procedure-steps-wrapper flex-1 $aspect-[3/4] md:w-[305px] md:h-[100%] max-w-[100%] mx-auto`}
                  >
                    <MVideoThumbnail
                      className={`$aspect-[3/4] w-fullmd:w-[305px] md:h-[545px] max-w-[100%] h-full items-center flex`}
                      video={step?.video}
                      notEmbedded
                      thumbnail={step.imageDesktop?.[0]?.url}
                    />
                  </div>
                )}
              </div>
              <div
                className={classNames(
                  'relative c-procedure-steps__right-side md:mx-6 '
                )}
              >
                {step.subheading && (
                  <h3
                    className={classNames('uppercase font-semibold text-h8', {
                      'text-neutral-white': isOdd,
                      'text-primary-teal': !isOdd,
                    })}
                  >
                    {step.subheading}
                  </h3>
                )}
                {step.heading && (
                  <h1
                    className={classNames('text-h7 mb-5', {
                      'text-neutral-white': isOdd,
                    })}
                  >
                    {step.heading}
                  </h1>
                )}
                <motion.div
                  onViewportEnter={() => {
                    setActiveStep(i)
                    setShowSticky(true)
                  }}
                ></motion.div>
                {step?.description && (
                  <div
                    className={classNames(
                      'c-procedure-steps__description mb-10',
                      { 'text-neutral-white': isOdd }
                    )}
                    dangerouslySetInnerHTML={{
                      __html: step?.description.replace(/\n/, '<br>'),
                    }}
                  />
                )}
                {step.button1.url && (
                  <AButton
                    className={classNames(
                      'w-full md:w-[300px] a-button a-button--size-md inline-flex items-center justify-center text-center font-semibold transition-all duration-300 align-middle border-2 border-transparent capitalize hover:text-neutral-white',
                      {
                        'bg-[#E6F6F7] text-primary-teal hover:bg-primary-dark-teal':
                          step.button2.url === null,
                        'bg-primary-dark-teal text-neutral-white hover:bg-primary-bright-teal':
                          step.button2.url !== null,
                      }
                    )}
                    isProcedure={true}
                    type={
                      step.button1.type === 'custom' ||
                      step.button1.type === 'entry' ||
                      step.button1.type === 'url'
                        ? 'a'
                        : step.button1.type
                    }
                    href={step.button1?.url}
                    rel={step.button1.type === 'url' ? 'noreferrer' : ''}
                    isDoIQualify={step.button1.text == 'Do I Qualify?'}
                  >
                    <span>{step.button1.text}</span>
                  </AButton>
                )}
                {step.button2.url && (
                  <AButton
                    className={classNames(
                      'w-full md:w-[300px] a-button a-button--size-md inline-flex items-center justify-center text-center font-semibold transition-all duration-300 align-middle border-2 border-transparent bg-[#E6F6F7] text-primary-teal hover:bg-primary-dark-teal mt-3 capitalize hover:text-neutral-white'
                    )}
                    isProcedure={true}
                    type={
                      step.button2.type === 'custom' ||
                      step.button2.type === 'entry' ||
                      step.button2.type === 'url'
                        ? 'a'
                        : step.button2.type
                    }
                    href={step.button2?.url}
                    rel={step.button2.type === 'url' ? 'noreferrer' : ''}
                    target={step.button2.target}
                  >
                    <span>{step.button2.text}</span>
                  </AButton>
                )}
              </div>
            </div>
          )
        })}
      </motion.div>
      {showSticky && (
        <>
          {!openDrawer ? (
            <div className="relative c-procedure-steps__mobile-sticky">
              <div className="blue-box relative">
                <p>Step {activeStep + 1}</p>
                {message[activeStep] && <p>{message[activeStep]}</p>}
                <div className={classNames('c-timeline__messageBox-pointer')} />
              </div>
              <div className="white-box">
                <p>{content?.step?.[activeStep].stepperTitle}</p>
                <div
                  className="lg:hidden"
                  onClick={() => setOpenDrawer(!openDrawer)}
                >
                  <AButton
                    type="button"
                    style="outline"
                    className="max-w-md w-full lg:w-auto"
                    icon={SvgArrowUpIcon}
                    iconClass="stroke-current"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="relative c-procedure-steps__mobile-sticky open">
              {content?.step.map((step, i) => {
                return (
                  <div
                    className={classNames('relative', {
                      'active-step': i === activeStep,
                    })}
                  >
                    <div className="blue-box">
                      <p>Step {i + 1}</p>
                      <p>{message[i]}</p>
                    </div>
                    <div className="white-box">{step.stepperTitle}</div>
                    <div
                      className={classNames('c-timeline__messageBox-pointer', {
                        'active-step': i === activeStep,
                      })}
                    />
                    {i === content?.step.length - 1 && (
                      <div
                        className="lg:hidden flex"
                        onClick={() => setOpenDrawer(!openDrawer)}
                      >
                        <AButton
                          type="button"
                          style="outline"
                          className="max-w-md w-full lg:w-auto"
                          icon={SvgArrowDownIcon}
                          iconClass="stroke-current"
                        />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </>
      )}
    </section>
  )
}

export const CProcedureStepsFragments = {
  field: `
    fragment procedureSteps on procedureSteps_MatrixField {
      ... on procedureSteps_block_BlockType {
        id
        step {
          ... on step_BlockType {
            id
            imageDesktop {
              url
              title
            }
            imageMobile {
              url
              title
            }
            video {
              url
              title
              embeddedAsset {
                url
                title
              }
            }
            heading
            description
            button2 {
              url
              target
              customText
              title
              text
              type
            }
            button1 {
              title
              customText
              target
              text
              url
              type
            }
            subheading
            stepperTitle
          }
        }
      }
    }
  `,
}
