import React, { MutableRefObject, RefObject } from 'react'
import { ASvg, AVideo, AVideoMethods, MPaginationDots } from 'ui'
import classNames from 'classnames'
import SvgIconPlay from 'ui/svg/icons/stroke/play.svg'
import './c-video-slider-us.css'
import TagManager from 'react-gtm-module'

type Props = {
  content: {
    heading?: string
    idName?: string
    slides?: any
  }
  sliderRef: RefObject<HTMLDivElement>
  slideMediaPlayed: boolean[]
  playSlideMedia: (slideIndex: number) => void
  slideMediaRef: MutableRefObject<(HTMLImageElement | AVideoMethods | null)[]>
  sliderIndex: number
  handlePaginationChangePage: (index: number) => void
}

export const CVideoSliderUs = ({
  content,
  sliderRef,
  slideMediaPlayed,
  playSlideMedia,
  slideMediaRef,
  sliderIndex,
  handlePaginationChangePage,
}: Props) => {
  return (
    <section
      id={content?.idName}
      className="mx-3 mb-25 mt-14 c-video-slider md:mx-6 lg:my-6"
    >
      <div className="relative flex flex-col justify-center mx-auto c-video-slider__container lg:pt-16 lg:pb-24">
        <h3 className="text-h6 lg:ml-10 lg:pl-10">Video Library</h3>

        <div ref={sliderRef} className="mt-8 lg:mt-12 glide">
          <div className="glide__track" data-glide-el="track">
            <div className="flex glide__slides">
              {content.slides?.map((slide: any, slideIndex: number) => (
                <section
                  key={slide?.video.title}
                  className="flex flex-col-reverse h-auto glide__slide c-video-slider__slide lg:flex-wrap lg:items-center"
                >
                  <div
                    className={classNames('relative', {
                      'lg:w-3/4': slideIndex !== sliderIndex + 1,
                      'lg:w-full': slideIndex === sliderIndex + 1,
                    })}
                  >
                    {slide?.video && (
                      <div
                        className={classNames('absolute inset-0 z-10', {
                          hidden: slideMediaPlayed[slideIndex],
                        })}
                      >
                        <AVideo
                          className="block w-full c-video-slider__slide-video"
                          playsInline
                          controls={false}
                          src={slide.video.url || ''}
                          aria-hidden="true"
                        />
                        <button
                          className="c-video-slider__slide-cover w-full h-full absolute inset-0 flex items-center justify-center"
                          aria-label={`Play Video: ${slide.video.title}`}
                          onClick={() => {
                            TagManager.dataLayer({
                              dataLayer: {
                                event: 'video',
                                event_category: 'youtube_video',
                                event_action: slide?.video?.title,
                                event_label: 'Video status | Video progress',
                              },
                            })
                            playSlideMedia(slideIndex)
                          }}
                        >
                          <div className="w-18 h-18 text-neutral-dark-gray hover:text-neutral-white bg-neutral-white rounded-full flex items-center justify-center hover:bg-primary-teal transition-colors duration-300 ease-out">
                            <ASvg
                              className="stroke-current fill-current w-12 h-12"
                              svg={SvgIconPlay}
                              ariaLabel="Play"
                            />
                          </div>
                        </button>
                      </div>
                    )}
                    {slide?.video && (
                      <AVideo
                        ref={(el) => {
                          slideMediaRef.current[slideIndex] =
                            el as AVideoMethods
                        }}
                        className="block w-full c-video-slider__slide-video"
                        playsInline
                        controls={true}
                        src={slide.video.url}
                      />
                    )}
                  </div>
                </section>
              ))}
            </div>
          </div>
          <div className="px-6 pb-4 -mt-16 z-20 relative lg:-mt-2 ml-auto mr-2 lg:p-0 lg:mt-2 flex lg:block justify-center ">
            <MPaginationDots
              className="lg:w-full mx-auto lg:mx-0 c-video-slider__pagination-dots dots-below"
              totalPages={content.slides?.length}
              currentPage={sliderIndex + 1}
              onChangePage={handlePaginationChangePage}
              showDots={false}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
