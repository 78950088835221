import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import Glide from '@glidejs/glide'
import { ASvg, AVideo, AVideoMethods, MPaginationDots } from 'ui'
import classNames from 'classnames'
import SvgIconPlay from 'ui/svg/icons/stroke/play.svg'
import { CVideoSliderUs } from './c-video-slider-us'
import { throttle } from 'lodash'
import './c-faq-slider.css'
import { useSiteInfo } from 'shared'
import TagManager from 'react-gtm-module'

export type CFaqSliderBlock = {
  __typename?: string
  idName?: string
  heading?: string
  primaryCopy?: string
  secondaryCopy?: string
  video: Array<CraftEmbeddedAssetField>
}

type Props = {
  field: Array<CFaqSliderBlock>
  isProcedure?: boolean
}

export const CFaqSlider = ({ field, isProcedure }: Props) => {
  const siteInfo = useSiteInfo()
  const isUS = siteInfo.isUS

  const sliderRef = useRef<HTMLDivElement>(null)
  const slideMediaRef = useRef<Array<AVideoMethods | null>>([])
  const glideRef = useRef<Glide | null>(null)

  const [isMobile, setIsMobile] = useState(false)
  const mobileBreakpoint = 1024
  const [sliderIndex, setSliderIndex] = useState<number>(0)
  const [slideMediaPlayed, setSlideMediaPlayed] = useState<Array<boolean>>([])

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < mobileBreakpoint)
  }

  const handleWindowResizeThrottled = throttle(handleWindowResize, 100)

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResizeThrottled)

    return () => {
      window.removeEventListener('resize', handleWindowResizeThrottled)
    }
  }, [])

  const content = useMemo(() => {
    const block = field?.find(
      (x) => x.__typename === 'faqVideoSlider_block_BlockType'
    )
    const slides = field?.map((f) => {
      if (f.__typename === 'faqVideoSlider_faqVideos_BlockType') {
        return {
          primaryCopy: f.primaryCopy,
          secondaryCopy: f.secondaryCopy,
          video: {
            title: f.video[0].embeddedAsset?.title,
            description: f.video[0].embeddedAsset?.description,
            url: f.video[0].embeddedAsset?.url,
            jsonUrl: f.video[0].url,
            type: f.video[0].embeddedAsset?.type,
          },
        }
      }
      return null
    })

    const filteredSlides = slides?.filter((s) => s !== null)

    return {
      ...block,
      slides: filteredSlides,
    }
  }, [field])

  const handlePaginationChangePage = (index: number) => {
    const isLeft = index < sliderIndex + 1
    if (glideRef.current) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'video_carousel_CTA',
          event_category: isLeft
            ? 'video_carousel_left_arrow'
            : 'video_carousel_right_arrow',
          event_action: 'cta',
        },
      })
      glideRef.current.go(`=${index - 1}`)
    }
  }

  const playSlideMedia = (slideIndex: number) => {
    setSlideMediaPlayed(
      slideMediaPlayed.map((item, i) => {
        return slideIndex === i ? true : item
      })
    )

    if (slideMediaRef.current) {
      slideMediaRef.current[slideIndex]?.play()
    }
  }

  useEffect(() => {
    if (content?.slides?.length) {
      setSlideMediaPlayed(Array(slideMediaRef.current.length).fill(false))

      const slider = new Glide(sliderRef.current ?? '', {
        direction: siteInfo.dir as 'ltr' | 'rtl' | undefined,
        gap: isUS ? (isMobile ? 10 : 0) : 0,
        rewind: isUS ? true : false,
        perView: isUS ? (isMobile ? 1 : 3) : 1,
        peek: isUS ? (isMobile ? 50 : 0) : 0,
        startAt: isUS ? (isMobile ? 1 : 0) : 0,
      })

      slider.mount()
      slider.on('run', () => {
        setSliderIndex(slider?.index ?? 0)
        slideMediaRef.current?.forEach((component) => {
          component?.pause()
        })
      })

      glideRef.current = slider
    }

    return () => {
      if (content && glideRef.current) {
        glideRef.current.destroy()
      }
    }
  }, [isMobile])

  if (!content?.slides?.length) {
    return <></>
  }

  if (siteInfo.isUS) {
    return (
      <CVideoSliderUs
        content={content}
        sliderRef={sliderRef}
        slideMediaPlayed={slideMediaPlayed}
        playSlideMedia={playSlideMedia}
        slideMediaRef={slideMediaRef}
        sliderIndex={sliderIndex}
        handlePaginationChangePage={handlePaginationChangePage}
      />
    )
  }

  return (
    <section
      id={content?.idName}
      className="mx-3 mb-20 mt-14 c-faq-slider md:mx-6 lg:my-6"
    >
      <div className="relative flex flex-col justify-center mx-auto c-faq-slider__container lg:pt-16 lg:pb-24">
        {content?.heading && (
          <h2
            className="font-normal text-h5 text-neutral-dark-gray c-faq-slider__heading whitespace-pre-line self-end"
            dangerouslySetInnerHTML={{ __html: content.heading }}
          />
        )}

        <div ref={sliderRef} className="mt-8 lg:mt-12 glide">
          <div className="glide__track" data-glide-el="track">
            <div className="flex glide__slides">
              {content.slides?.map((slide, slideIndex) => (
                <section
                  key={slide?.video.title}
                  className="flex flex-col-reverse h-auto glide__slide c-faq-slider__slide lg:flex-row lg:flex-wrap lg:items-center"
                >
                  <div className="flex flex-col items-center flex-grow p-6 mt-3 md:mt-6 lg:mt-0 lg:w-1/2 lg:py-0 bg-primary-bright-teal lg:bg-transparent c-faq-slider__slide-body">
                    <div className="w-full c-faq-slider__slide-content">
                      <p className="font-normal text-p-lg sm:text-blockquote c-faq-slider__slide-blockquote text-neutral-white">
                        {slide?.primaryCopy}
                      </p>
                      <p className="mt-4 c-faq-slider__slide-attribution">
                        {slide?.secondaryCopy}
                      </p>
                    </div>
                  </div>

                  <div className="lg:w-1/2 relative">
                    {slide?.video && (
                      <div
                        className={classNames('absolute inset-0 z-10', {
                          hidden: slideMediaPlayed[slideIndex],
                        })}
                      >
                        <AVideo
                          className="block w-full c-faq-slider__slide-video"
                          playsInline
                          controls={false}
                          src={slide.video.url || ''}
                          aria-hidden="true"
                        />
                        <button
                          className="c-faq-slider__slide-cover w-full h-full absolute inset-0 flex items-center justify-center"
                          aria-label={`Play Video: ${slide.video.title}`}
                          onClick={() => playSlideMedia(slideIndex)}
                        >
                          <div className="w-20 h-20 text-neutral-dark-gray hover:text-neutral-white bg-neutral-white rounded-full flex items-center justify-center hover:bg-primary-teal transition-colors duration-300 ease-out">
                            <ASvg
                              className="stroke-current fill-current w-6 h-6"
                              svg={SvgIconPlay}
                              ariaLabel="Play"
                            />
                          </div>
                        </button>
                      </div>
                    )}
                    {slide?.video && (
                      <AVideo
                        ref={(el) => {
                          slideMediaRef.current[slideIndex] =
                            el as AVideoMethods
                        }}
                        className="block w-full c-faq-slider__slide-video"
                        playsInline
                        controls={true}
                        src={slide.video.url}
                      />
                    )}
                  </div>
                </section>
              ))}
            </div>
          </div>
          <div className="px-6 pb-4 -mt-16 z-20 relative lg:-mt-2 ml-auto mr-2 lg:p-0 lg:mt-2 flex lg:block justify-center">
            <MPaginationDots
              className="lg:w-full mx-auto lg:mx-0 c-faq-slider__pagination-dots"
              totalPages={content.slides?.length}
              currentPage={sliderIndex + 1}
              onChangePage={handlePaginationChangePage}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const CFaqSliderFragments = {
  field: `
    fragment faqSlider on faqVideoSlider_MatrixField {
      ... on faqVideoSlider_block_BlockType {
        __typename
        idName
        heading
      }
      ... on faqVideoSlider_faqVideos_BlockType {
        __typename
        primaryCopy
        secondaryCopy
        video {
          title
          slug
          url
          embeddedAsset {
            title
            description
            url
            type
          }
        }
      }
    }
  `,
}
