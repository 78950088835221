import { useState } from 'react'
import Image from 'next/image'
import { useIntercom } from 'react-use-intercom'
import classNames from 'classnames'
import { CContactFormBlock } from './c-contact-form'
import { CFormie } from './c-formie'
import { CContactModal } from './c-contact-modal'

interface CContactFormUsProps {
  content: CContactFormBlock | null
  setShowModal: (param: boolean) => void
  showModal: boolean
}

export const CContactFormUs = ({
  content,
  setShowModal,
  showModal,
}: CContactFormUsProps) => {
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false)
  const { show } = useIntercom()

  const transformedActions = content?.actions?.map((action) => ({
    icon: `./img/${action.actionType}-icon.svg`,
    title: action.actionTitle,
    subtitle: action.actionValue,
    action: () => {
      switch (action.actionType) {
        case 'phone':
          window.location.href = `tel:${action.actionValue}`
          break
        case 'email':
          setIsFormVisible(!isFormVisible)
          break
        case 'chat':
          show()
          break
        default:
          {
          }
          break
      }
    },
  }))

  return (
    <section id={content?.idName} className="c-contact-form mt-24 md:mt-32">
      <div className="mb-6 o-container o-container--2xl">
        <div className="flex items-stretch w-full md:p-4 md:pb-20">
          <div className="w-full md:px-12 lg:max-w-[58%] flex flex-col">
            {content?.heading && (
              <h1 className="mt-6 md:mt-12 text-4xl font-semibold text-neutral-dark-gray">
                {content?.heading}
              </h1>
            )}

            {content?.subheading && (
              <h2 className="mt-6 text-p md:text-2xl text-neutral-dark-gray">
                {content.subheading}
              </h2>
            )}

            {content?.description && (
              <div
                className="mt-6 text-p md:text-2xl text-neutral-dark-gray [&>p>a]:font-semibold [&>p>a]:underline [&>p>a]:text-primary-dark-teal"
                dangerouslySetInnerHTML={{ __html: content?.description }}
              />
            )}

            <div className="flex justify-center gap-[42px] md:gap-[105px] mt-14 md:max-w-[83%] mx-auto">
              {transformedActions?.map((action, i) => {
                return (
                  <button
                    key={`${action.title?.toLocaleLowerCase()}-${i}`}
                    className="flex flex-col items-center"
                    onClick={action.action}
                  >
                    <span className="flex justify-center bg-primary-teal w-[75px] h-[75px] rounded-[15px] mb-4">
                      <Image
                        src={action.icon}
                        alt={action.title ?? ''}
                        width={47}
                        height={47}
                      />
                    </span>
                    <span className="flex flex-col text-lg md:text-2xl">
                      {action.title}
                      {action.subtitle && (
                        <span className="text-sm md:text-lg mt-2">
                          {action.subtitle}
                        </span>
                      )}
                    </span>
                  </button>
                )
              })}
            </div>

            <div
              className={classNames('px-6 py-8 bg-gray-100 mt-8 md:mt-16', {
                hidden: !isFormVisible,
              })}
            >
              <h4 className="text-2xl font-semibold">
                {content?.formieHeading}
              </h4>
              <CFormie
                className="formie-default-visible"
                field={content?.formie}
                showModal={() => setShowModal(true)}
              />
              <CFormie
                className="formie-default-hidden hidden"
                field={content?.hiddenFormie}
                showModal={() => setShowModal(true)}
              />
            </div>

            {content?.bottomContent && (
              <div
                className="mt-auto pt-14 text-p md:text-2xl text-neutral-dark-gray [&>p>a]:font-semibold [&>p>a]:underline [&>p>a]:text-primary-dark-teal"
                dangerouslySetInnerHTML={{ __html: content?.bottomContent }}
              />
            )}

            <CContactModal
              open={showModal}
              onClose={() => setShowModal(false)}
              heading={content?.successHeading}
              subheading={content?.successSubheading}
              button={content?.successButton}
            />
          </div>

          {content?.imageDesktop?.length &&
            content?.imageDesktop?.length > 0 && (
              <div className="flex-1 flex-shrink-0 hidden lg:block mt-[45px] relative">
                <div className=" pl-4 relative after:content-[''] after:block after:w-[268px] after:h-[272px] after:bg-secondary-orange-dark after:absolute after:-top-[16px] after:-right-[13px] after:-z-[1] before:content-[''] before:block before:w-[118px] before:h-[268px] before:bg-secondary-orange-dark before:absolute before:-bottom-[16px] before:left-[0] before:-z-[1]">
                  <img
                    className="block w-full"
                    alt={content?.imageDesktop[0].title}
                    src={content?.imageDesktop[0].url}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </section>
  )
}
