const settings = {
  autocomplete: 3,
  contact: {
    modal: false,
    subscribe: false,
  },
  country: null,
  language: null,
  list: {
    avatar: ["doctor"],
  },
  map: {
    lat: 0,
    lng: 0,
    zoom: 5,
  },
  radius: {
    default: "8",
    options: [
      { label: "8", value: "8" },
      { label: "16", value: "16" },
      { label: "40", value: "40" },
    ],
    queryOverride: [],
  },
  rankPerRegion: false,
  rtl: false,
  search: "geocoding",
  sort: {
    default: "experience",
    options: [
      { label: "experience", value: "experience" },
      { label: "distance", value: "proximity" },
    ],
  },
  types: {
    default: "doctors",
    options: [
      { label: "doctors", value: "doctors" },
      { label: "locations", value: "locations" },
    ],
  },
  unit: {
    label: "Km",
    value: 1000,
  },
};

const locales = {
  ar: {
    country: ["sa"],
    map: {
      lat: 23,
      lng: 44,
      zoom: 4.5,
    },
    rtl: true,
  },
  "de-CH": {
    autocomplete: 3,
    map: {
      lat: 46.8,
      lng: 7.6,
      zoom: 6,
    },
  },
  "de-DE": {
    country: ["de", "at"],
    map: {
      lat: 51,
      lng: 10,
      zoom: 5,
    },
  },
  "en-AU": {
    country: [
      "au", // Australia
      "nz", // New Zealand
      "sg", // Singapore
      "lk",
      "ph", // Philippines
      "my", // Malaysia
      "th", // Thailand
      "vn", // Vietnam
      "id", // Indonesia
    ],
    map: {
      lat: -27.5,
      lng: 131.5,
      zoom: 3,
    },
    radius: {
      ...settings.radius,
      default: "16",
    },
    types: {
      default: "doctors",
      options: [{ label: "doctors", value: "doctors" }],
    },
  },
  "en-CA": {
    autocomplete: 3,
    map: {
      lat: 59,
      lng: -98.5,
      zoom: 3.2,
    },
    radius: {
      ...settings.radius,
      default: "80",
      options: [
        { label: "16", value: "16" },
        { label: "40", value: "40" },
        { label: "80", value: "80" },
      ],
    },
  },
  "en-GB": {
    map: {
      lat: 54.5,
      lng: -4,
      zoom: 5,
    },
    radius: {
      ...settings.radius,
      default: "40",
    },
    types: {
      default: "doctors",
      options: [{ label: "providers", value: "doctors" }],
    },
  },
  "en-IN": {
    map: {
      lat: 20,
      lng: 78,
      zoom: 4.2,
    },
    radius: {
      ...settings.radius,
      default: "16",
      options: [
        { label: "16", value: "16" },
        { label: "40", value: "40" },
      ],
    },
    types: {
      default: 'locations',
      options: [{ label: 'locations', value: 'locations' }],
    }
  },
  "en-US": {
    autocomplete: 3,
    map: {
      lat: 37.5,
      lng: -94.5,
      zoom: 3,
    },
    radius: {
      ...settings.radius,
      default: "25",
      allianceCutoffRadius: "50",
      allianceMaxRadius: "200",
      options: [
        { label: "10", value: "10" },
        { label: "25", value: "27" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
        { label: "200", value: "200" },
      ],
      queryOverride: [
        { text: "New York City", default: "10" },
        { text: "Sherman Oaks", default: "10" },
        { text: "Tacoma", default: "50" },
      ],
    },
    sort: {
      ...settings.sort,
      default: "tiered",
      options: [...settings.sort.options, { label: "tiered", value: "tiered" }],
    },
    unit: {
      label: "Miles",
      value: 1609.344,
    },
    country: [
      'us', // United States
      'pr', // Puerto Rico
    ]
  },
  es: {
    map: {
      lat: -4.44,
      lng: -61.33,
      zoom: 4,
    },
    radius: {
      default: '40',
      allianceCutoffRadius: '80',
      allianceMaxRadius: '160',
      options: [
        { label: '16', value: '16' },
        { label: '40', value: '40' },
        { label: '80', value: '80' },
        { label: '160', value: '160' },
      ],
      queryOverride: [],
    },
  },
  "es-ES": {
    map: {
      lat: 39.5,
      lng: -2.5,
      zoom: 5,
    },
    search: "regions",
  },
  "fa-IR": {
    map: {
      lat: 33,
      lng: 55,
      zoom: 4.8,
    },
    radius: {
      ...settings.radius,
      default: "160",
      options: [
        { label: "8", value: "8" },
        { label: "16", value: "16" },
        { label: "40", value: "40" },
        { label: "80", value: "80" },
        { label: "160", value: "160" },
      ],
    },
    rtl: true,
  },
  "fr-BE": {
    autocomplete: 3,
    map: {
      lat: 50.5,
      lng: 4.5,
      zoom: 7,
    },
  },
  "fr-CA": {
    autocomplete: 3,
    map: {
      lat: 59,
      lng: -98.5,
      zoom: 3.2,
    },
    radius: {
      ...settings.radius,
      default: "80",
      options: [
        { label: "16", value: "16" },
        { label: "40", value: "40" },
        { label: "80", value: "80" },
      ],
    },
  },
  "fr-FR": {
    map: {
      lat: 47,
      lng: 2,
      zoom: 5.2,
    },
    radius: {
      ...settings.radius,
      default: "16",
    },
  },
  "it-IT": {
    autocomplete: 3,
    map: {
      lat: 42,
      lng: 13,
      zoom: 5.2,
    },
  },
  "ja-JP": {
    autocomplete: 2,
    list: {
      avatar: ["location"],
    },
    map: {
      lat: 38.5,
      lng: 137,
      zoom: 4.8,
    },
    radius: {
      ...settings.radius,
      default: "10",
      options: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "100", value: "101" },
        { label: "200", value: "200" },
      ],
    },
    sort: {
      default: "experience",
      options: [{ label: "experience", value: "experience" }],
    },
    types: {
      default: "locations",
      options: [{ label: "locations", value: "locations" }],
    },
  },
  "ko-KR": {
    autocomplete: 2,
    radius: {
      ...settings.radius,
      default: "16",
    },
    map: {
      lat: 36.5,
      lng: 127.5,
      zoom: 6.5,
    },
    types: {
      default: "locations",
      options: [{ label: "locations", value: "locations" }],
    },
  },
  "nb-NO": {
    autocomplete: 3,
    map: {
      lat: 64.3,
      lng: 15.8,
      zoom: 3.8,
    },
  },
  nl: {
    autocomplete: 3,
    country: ["be"],
    language: "nl-BE",
    map: {
      lat: 50.5,
      lng: 4.5,
      zoom: 7,
    },
  },
  "nl-NL": {
    autocomplete: 3,
    map: {
      lat: 52.2,
      lng: 4.5,
      zoom: 7,
    },
  },
  "pt-BR": {
    map: {
      lat: -14.24,
      lng: -51.93,
      zoom: 4,
    },
    country: [
      // Central America and the Caribbean
      'bz', // Belize
      'cr', // Costa Rica
      'cu', // Cuba
      'do', // Dominican Republic
      'gt', // Guatemala
      'ht', // Haiti
      'hn', // Honduras
      'mx', // Mexico
      'ni', // Nicaragua
      'pa', // Panama
      'pr', // Puerto Rico
      'sv', // El Salvador

      // South America
      'ar', // Argentina
      'bo', // Bolivia
      'br', // Brazil
      'cl', // Chile
      'co', // Colombia
      'ec', // Ecuador
      'gf', // French Guiana
      'gy', // Guyana
      'mq', // Martinique
      'pe', // Peru
      'py', // Paraguay
      'sr', // Suriname
      'uy', // Uruguay
      've', // Venezuela

      // Lesser Antilles
      'bl', // Saint-Barthélemy
      'mf', // Saint-Martin
      'gp', // Guadeloupe
    ],
  },
  "pt-PT": {
    map: {
      lat: 39.5,
      lng: -13,
      zoom: 5,
    },
    search: "regions",
  },
  "sv-SE": {
    autocomplete: 3,
    map: {
      lat: 62.7,
      lng: 14.3,
      zoom: 3.9,
    },
  },
  "vi-VN": {
    country: ["vn"],
    map: {
      lat: 14.315424,
      lng: 108.339537,
      zoom: 4.9,
    },
  },
  "zh-Hans": {
    country: ["us"],
    map: {
      lat: 37.5,
      lng: -94.5,
      zoom: 3,
    },
    radius: {
      ...settings.radius,
      default: "25",
      allianceCutoffRadius: "50",
      allianceMaxRadius: "200",
      options: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
        { label: "200", value: "200" },
      ],
    },
    sort: {
      ...settings.sort,
      default: "tiered",
      options: [...settings.sort.options, { label: "tiered", value: "tiered" }],
    },
    unit: {
      label: "Miles",
      value: 1609.344,
    },
  },
};

const layouts = {
  evo: {
    "de-DE": {
      search: "regions",
    },
    "fr-BE": {
      search: "regions",
    },
    "nl-BE": {
      search: "regions",
    },
  },
  icl: {
    "de-DE": {
      radius: {
        ...settings.radius,
        default: "16",
      },
      types: {
        default: "locations",
        options: [{ label: "locations", value: "locations" }],
      },
    },
    "en-US": {
      contact: {
        modal: true,
        subscribe: true,
      },
      country: [
        'us', // United States
        'pr', // Puerto Rico
      ]
    },
    'en-IN': {
      types: {
        default: 'locations',
        options: [{ label: 'locations', value: 'locations' }],
      },
    },
    es: {
      // Latin America
      country: [
        // Central America and the Caribbean
        "bz", // Belize
        "cr", // Costa Rica,
        "cu", // Cuba
        "do", // Dominican Republic
        "sv", // El Salvador
        "gt", // Guatemala
        "ht", // Haiti
        "hn", // Honduras
        "jm", // Jamaica
        "mx", // Mexico
        "ni", // Nicaragua
        "pa", // Panama
        "lc", // Saint Lucia

        // South America
        "ar", // Argentina
        "bo", // Bolivia
        "br", // Brasil
        "cl", // Chile
        "co", // Colombia
        "ec", // Equador
        "py", // Paraguay
        "pe", // Peru
        "uy", // Uruguay
        "ve", // Venezuela
      ],
    },
    "es-ES": {
      rankPerRegion: true,
      types: {
        default: "doctors",
        options: [{ label: "doctors", value: "doctors" }],
      },
    },
    "pt-BE": {
      rankPerRegion: true,
      types: {
        default: "doctors",
        options: [{ label: "doctors", value: "doctors" }],
      },
    },
    "pt-PT": {
      rankPerRegion: true,
      types: {
        default: "doctors",
        options: [{ label: "doctors", value: "doctors" }],
      },
    },
    'pt-BR': {
      country: [
      // Central America and the Caribbean
        'bz', // Belize
        'cr', // Costa Rica
        'cu', // Cuba
        'do', // Dominican Republic
        'gt', // Guatemala
        'ht', // Haiti
        'hn', // Honduras
        'mx', // Mexico
        'ni', // Nicaragua
        'pa', // Panama
        'pr', // Puerto Rico
        'sv', // El Salvador

      // South America
        'ar', // Argentina
        'bo', // Bolivia
        'br', // Brazil
        'cl', // Chile
        'co', // Colombia
        'ec', // Ecuador
        'gf', // French Guiana
        'gy', // Guyana
        'mq', // Martinique
        'pe', // Peru
        'py', // Paraguay
        'sr', // Suriname
        'uy', // Uruguay
        've', // Venezuela

      // Lesser Antilles
        'bl', // Saint-Barthélemy
        'mf', // Saint-Martin
        'gp', // Guadeloupe
      ],
    },
    'en-GB': {
      contact: {
        modal: false,
        subscribe: true,
      },
      country: [
        // Europe
        "gb", // Great Britain
        "ie", // Ireland
        "si", // Slovenia
        "ua", // Ukraine

        // Middle East
        "ir", // Iran
        "iq", // Iraq
        "jo", // Jordan
        "kw", // Kuwait
        "lb", // Lebanon
        "om", // Oman
        "sa", // Saudi Arabia
        "sy", // Syria
        "ye", // Yemen

        // Latin America
        "ar", // Argentina
        "br", // Brazil
        "co", // Colombia
        "cl", // Chile
        "cr", // Costa Rica
        "do", // Dominican Republic
        "ec", // Ecuador
        "sv", // El Salvador
        "mx", // Mexico
        "pe", // Peru
        "pr", // Puerto Rico
        "uy", // Uruguay
        "ve", // Venezuela

        // North Africa
        "dz", // Algeria
        "eg", // Egypt
        "ly", // Lybia
        "ma", // Morocco
        "tn", // Tunisia
      ],
    },
    "en-AU": {
      country: [
        "au", // Australia
        "nz", // New Zealand
        "sg", // Singapore
        "lk",
        "ph", // Philippines
        "my", // Malaysia
        "th", // Thailand
        "vn", // Vietnam
        "id", // Indonesia
      ],
    },
  },
};

export { settings, layouts, locales };
