import Link from 'next/link'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import {
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  MouseEventHandler,
  MouseEvent,
} from 'react'
import TagManager from 'react-gtm-module'
import { throttle } from 'lodash'
import { ASvg } from 'ui/components/atoms/a-svg'
import { AButton } from 'ui/components/atoms/a-button'
import SvgEvoVisianIclLogo from 'ui/svg/logos/evo-visian-icl/evo-icl-linear-black.svg'
import SvgStaarSurgicalLogo from 'ui/svg/logos/staar-surgical/teal-black.svg'
import SvgVisianIclLogo from 'ui/svg/logos/visian-icl/teal-black.svg'
import SvgIconArrowDown from 'ui/svg/icons/fill/arrow-down.svg'
import SvgSearchIcon from 'ui/svg/icons/stroke/search.svg'
import classNames from 'classnames'
import './c-the-header.css'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import SvgHamburgerIcon from 'ui/svg/icons/fill/hamburger.svg'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { HeaderContext } from '../../context/header'
import { CTheMenu } from './c-the-menu'

type Props = {
  isVisible?: boolean
  position?: string
  theme?: string
  isClear?: boolean
  isClearDark?: boolean
  hasProgressBar?: boolean
  countryCode?: string
  brand?: string
  doctorsUrl?: string
  quizUrl?: string
  backendUrl?: string
  baseDomain?: string
  fullDomain?: string
  discoveryUrl?: string
}

const defaultProps = {
  isVisible: true,
  position: 'fixed',
  theme: 'light',
  isClear: false,
  isClearDark: false,
  hasProgressBar: false,
  countryCode: '',
  brand: '',
  doctorsUrl: '',
  quizUrl: '',
  backendUrl: '',
  baseDomain: '',
  fullDomain: '',
}

type CustomLinkProps = {
  className?: string
  href?: string
  onClick?: MouseEventHandler
  children?: any
}

const CustomLink = ({
  className,
  href,
  onClick,
  children,
}: CustomLinkProps) => {
  if (/^http/.test(href ?? '')) {
    return (
      <a className={className} href={href} onClick={onClick}>
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={className}
        href={href ?? ''}
        onClick={onClick}
        prefetch={false}
      >
        {children}
      </Link>
    )
  }
}

const CTheHeader = (props: Props) => {
  const siteInfo = useSiteInfo()
  const { t } = useTranslation('common')
  const headerContext = useContext(HeaderContext)

  const [pageScrollProgress, setPageScrollProgress] = useState<number>(0)
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)

  const menuRef = useRef()

  const countryFlagCode = useMemo(() => {
    switch (props.countryCode) {
      case 'ar':
        return 'ar'
      case 'es':
        return 'la'
      case 'nl':
        return 'be'
      case 'en':
        return 'ea'
      case 'zh-Hans':
        return 'us'
      default:
        return props.countryCode?.slice(3) ?? ''
    }
  }, [props.countryCode])

  const countryCode = useMemo(() => {
    switch (props.countryCode) {
      case 'ar':
        return 'ar'
      case 'es':
        return 'la'
      case 'nl':
        return 'be'
      case 'en':
        return 'ea'
      case 'zh-Hans':
        return 'us'
      case 'en-GB':
        return 'UK'
      default:
        return props.countryCode?.slice(3) ?? ''
    }
  }, [props.countryCode])

  const handleNavigationLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'headerClicks',
        event_category: 'Header Clicks',
        event_action: event.currentTarget.innerText,
        event_label: event.currentTarget.className,
      },
    })
  }

  const handleQuizButtonClick = () => {
    //
  }

  const handleDoctorsButtonClick = () => {
    //
  }

  const handleLocaleButtonClick = () => {
    headerContext?.setShowLocaleSwitcher(true)
  }

  const handleWindowScroll = () => {
    setPageScrollProgress(
      window.pageYOffset / (document.body.scrollHeight - window.innerHeight)
    )
  }

  const openMenu = () => {
    setIsOpenMenu(true)
    if (menuRef.current) {
      disableBodyScroll(menuRef.current)
    }
  }

  const closeMenu = () => {
    setIsOpenMenu(false)
    if (menuRef.current) {
      enableBodyScroll(menuRef.current)
    }
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScrollThrottled)

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
    }
  }, [])

  return (
    <header
      className={classNames(
        'c-the-header top-0 z-50 w-full inset-inline-0',
        props.position,
        {
          'invisible opacity-0': !props.isVisible,
          'text-neutral-white': props.isClear && !props.isClearDark,
          'text-neutral-white md:text-neutral-dark-gray':
            props.isClear && props.isClearDark,
          'bg-neutral-white text-neutral-dark-gray':
            !props.isClear && props.theme === 'light',
          'bg-neutral-black text-neutral-white':
            !props.isClear && props.theme === 'dark',
        }
      )}
    >
      <div className="bg-[#242424] text-[#817c7d] h-[34px] items-center justify-end hidden px-5 md:flex xl:px-10">
        {['en-US', 'zh-Hans'].includes(props.countryCode ?? '') && (
          <>
            <CustomLink
              className={classNames(
                'font-bold tracking-wider uppercase transition duration-300 text-p-sm',
                props.countryCode === 'zh-Hans'
                  ? 'text-neutral-gray'
                  : 'hover:text-neutral-gray'
              )}
              href={`https://sc.${props.baseDomain}`}
            >
              简体中文
            </CustomLink>
            <div className="mx-4 bg-[#817c7d] w-[1px] h-[18px]" />
            <CustomLink
              className={classNames(
                'font-bold tracking-wider uppercase transition duration-300 text-p-sm',
                props.countryCode === 'en-US'
                  ? 'text-neutral-gray'
                  : 'hover:text-neutral-gray'
              )}
              href={`https://us.${props.baseDomain}`}
            >
              English
            </CustomLink>
            <div className="mx-4 bg-[#817c7d] w-[1px] h-[18px]" />
          </>
        )}
        {t('paths.safety-information') && !siteInfo.isES && (
          <>
            <CustomLink
              className="font-bold tracking-wider uppercase transition duration-300 text-p-sm hover:text-neutral-gray"
              href={`${props.discoveryUrl}${t('paths.safety-information')}`}
            >
              {t('titles.safety-information')}
            </CustomLink>
            <div className="mx-4 bg-[#817c7d] w-[1px] h-[18px]" />
          </>
        )}
        <button
          title="Change region"
          className="flex items-center font-bold tracking-wider uppercase transition duration-300 text-p-sm hover:text-neutral-gray"
          onClick={handleLocaleButtonClick}
        >
          <Image
            className="mx-2"
            src={`${
              props.backendUrl
            }img/flags/${countryFlagCode.toLowerCase()}.png`}
            aria-hidden="true"
            alt={countryCode}
            width={23}
            height={12}
          />
          <span className="uppercase">{countryCode}</span>
        </button>
      </div>
      <div className="c-the-header__main flex items-center justify-between px-5 xl:px-10 h-[72px]">
        <CustomLink className="inline-block" href={props?.fullDomain?.includes('doctors') || props?.fullDomain?.includes('quiz') ? `https://${countryCode}.discovericl.com` : props?.fullDomain}>
          {props.brand === CONST.BRANDED.VISIAN_ICL && (
            <ASvg
              className={classNames('w-[160px] h-[32px]', {
                'fill-current text-neutral-white c-the-header__logo-graphic':
                  props.isClear || (!props.isClear && props.theme === 'dark'),
                'fill-current text-neutral-dark-gray c-the-header__logo-graphic':
                  !props.isClear && props.theme === 'light',
              })}
              svg={SvgVisianIclLogo}
              ariaLabel={`Visian ICL ${t('logo')}`}
            />
          )}
          {props.brand === CONST.BRANDED.STAAR_SURGICAL && (
            <ASvg
              className={classNames('w-[160px] h-[32px]', {
                'fill-current text-neutral-white c-the-header__logo-graphic':
                  props.isClear || (!props.isClear && props.theme === 'dark'),
                'fill-current text-neutral-dark-gray c-the-header__logo-graphic':
                  !props.isClear && props.theme === 'light',
              })}
              svg={SvgStaarSurgicalLogo}
              ariaLabel={`Staar Surgical ${t('logo')}`}
            />
          )}
          {props.brand === CONST.BRANDED.EVO_VISIAN_ICL && (
            <ASvg
              className={classNames('w-[160px] h-[32px]', {
                'fill-current text-neutral-white':
                  props.isClear || (!props.isClear && props.theme === 'dark'),
                'fill-current text-neutral-dark-gray':
                  !props.isClear && props.theme === 'light',
              })}
              svg={SvgEvoVisianIclLogo}
              ariaLabel={`EVO Visian ${t('logo')}`}
            />
          )}
        </CustomLink>
        <nav aria-label={`${t('header')} ${t('navigation')}`}>
          <ul className="flex items-center">
            {!siteInfo.isES && (
              <>
                {t('paths.lens') && (
                  <li className="c-the-header__nav-item hidden pis-8 xl:block">
                    <CustomLink
                      className={classNames(
                        'font-bold text-center uppercase transition-all duration-300 text-button align-middle text-neutral-white hover:text-neutral-light-gray',
                        {
                          'text-neutral-white hover:text-neutral-light-gray':
                            (props.isClear && !props.isClearDark) ||
                            (!props.isClear && props.theme === 'dark'),
                          'text-neutral-white hover:text-neutral-light-gray md:text-neutral-dark-gray md:hover:text-neutral-black':
                            props.isClear && props.isClearDark,
                          'text-neutral-dark-gray hover:text-neutral-black':
                            !props.isClear && props.theme === 'light',
                        }
                      )}
                      href={`${props.discoveryUrl}${t('paths.lens')}`}
                      onClick={handleNavigationLinkClick}
                    >
                      <span>{t('titles.the-lens')}</span>
                    </CustomLink>
                  </li>
                )}
                {(t('paths.nightvision') ||
                  t('paths.dry-eye') ||
                  t('paths.harmony')) && (
                  <li className="c-the-header__nav-item hidden pis-8 xl:block">
                    <button
                      className={classNames(
                        'inline-flex items-center justify-center font-bold text-center uppercase text-button align-middle text-neutral-white hover:text-neutral-light-gray',
                        {
                          'text-neutral-white hover:text-neutral-light-gray':
                            (props.isClear && !props.isClearDark) ||
                            (!props.isClear && props.theme === 'dark'),
                          'text-neutral-white hover:text-neutral-light-gray md:text-neutral-dark-gray md:hover:text-neutral-black':
                            props.isClear && props.isClearDark,
                          'text-neutral-dark-gray hover:text-neutral-black':
                            !props.isClear && props.theme === 'light',
                        }
                      )}
                    >
                      <span>{t('benefits')}</span>
                      <ASvg
                        className="fill-current"
                        svg={SvgIconArrowDown}
                        ariaHidden="true"
                      />
                    </button>
                    <div className="relative -mx-16">
                      <ul className="c-the-header__nav-dropdown absolute top-0 left-0 z-30 w-full transition-all duration-300 m-dropdown text-neutral-dark-gray">
                        {!siteInfo.isIN && (
                          <li className="bg-neutral-light-gray">
                            <CustomLink
                              className="block p-4 font-semibold transition-colors duration-300 text-p hover:text-neutral-black"
                              href={`${props.discoveryUrl}${t(
                                'paths.nightvision'
                              )}`}
                              onClick={handleNavigationLinkClick}
                            >
                              {t('titles.night-vision')}
                            </CustomLink>
                          </li>
                        )}
                        <li className="bg-neutral-light-gray border-neutral-gray border-t">
                          <CustomLink
                            className="block p-4 font-semibold transition-colors duration-300 text-p hover:text-neutral-black"
                            href={`${props.discoveryUrl}${t('paths.dry-eye')}`}
                            onClick={handleNavigationLinkClick}
                          >
                            {t('titles.no-dry-eye')}
                          </CustomLink>
                        </li>
                        <li className="bg-neutral-light-gray border-neutral-gray border-t">
                          <CustomLink
                            className="block p-4 font-semibold transition-colors duration-300 text-p hover:text-neutral-black"
                            href={`${props.discoveryUrl}${t('paths.harmony')}`}
                            onClick={handleNavigationLinkClick}
                          >
                            {t('titles.harmony-w-natural-eye')}
                          </CustomLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}
                {t('paths.procedure') && (
                  <li className="c-the-header__nav-item hidden pis-8 xl:block">
                    <CustomLink
                      className={classNames(
                        'font-bold text-center uppercase transition-all duration-300 text-button align-middle text-neutral-white hover:text-neutral-light-gray',
                        {
                          'text-neutral-white hover:text-neutral-light-gray':
                            (props.isClear && !props.isClearDark) ||
                            (!props.isClear && props.theme === 'dark'),
                          'text-neutral-white hover:text-neutral-light-gray md:text-neutral-dark-gray md:hover:text-neutral-black':
                            props.isClear && props.isClearDark,
                          'text-neutral-dark-gray hover:text-neutral-black':
                            !props.isClear && props.theme === 'light',
                        }
                      )}
                      href={`${props.discoveryUrl}${t('paths.procedure')}`}
                      onClick={handleNavigationLinkClick}
                    >
                      <span>{t('titles.procedure')}</span>
                    </CustomLink>
                  </li>
                )}
                {t('paths.why-visian') && (
                  <li className="c-the-header__nav-item hidden pis-8 xl:block">
                    <CustomLink
                      className={classNames(
                        'font-bold text-center uppercase transition-all duration-300 text-button align-middle text-neutral-white hover:text-neutral-light-gray',
                        {
                          'text-neutral-white hover:text-neutral-light-gray':
                            (props.isClear && !props.isClearDark) ||
                            (!props.isClear && props.theme === 'dark'),
                          'text-neutral-white hover:text-neutral-light-gray md:text-neutral-dark-gray md:hover:text-neutral-black':
                            props.isClear && props.isClearDark,
                          'text-neutral-dark-gray hover:text-neutral-black':
                            !props.isClear && props.theme === 'light',
                        }
                      )}
                      href={`${props.discoveryUrl}${t('paths.why-visian')}`}
                      onClick={handleNavigationLinkClick}
                    >
                      <span>{t('titles.why-visian')}</span>
                    </CustomLink>
                  </li>
                )}
                {t('paths.life-with-visian') && !siteInfo.isNL && !siteInfo.isIN && (
                  <li className="c-the-header__nav-item hidden pis-8 xl:block">
                    <CustomLink
                      className={classNames(
                        'font-bold text-center uppercase transition-all duration-300 text-button align-middle text-neutral-white hover:text-neutral-light-gray',
                        {
                          'text-neutral-white hover:text-neutral-light-gray':
                            (props.isClear && !props.isClearDark) ||
                            (!props.isClear && props.theme === 'dark'),
                          'text-neutral-white hover:text-neutral-light-gray md:text-neutral-dark-gray md:hover:text-neutral-black':
                            props.isClear && props.isClearDark,
                          'text-neutral-dark-gray hover:text-neutral-black':
                            !props.isClear && props.theme === 'light',
                        }
                      )}
                      href={`${props.discoveryUrl}${t('paths.life-with-visian')}`}
                      onClick={handleNavigationLinkClick}
                    >
                      <span>{t('titles.life-with-visian')}</span>
                    </CustomLink>
                  </li>
                )}
                {t('paths.faq') && !siteInfo.isIN && (
                  <li className="c-the-header__nav-item hidden pis-8 xl:block">
                    <CustomLink
                      className={classNames(
                        'font-bold text-center uppercase transition-all duration-300 text-button align-middle text-neutral-white hover:text-neutral-light-gray',
                        {
                          'text-neutral-white hover:text-neutral-light-gray':
                            (props.isClear && !props.isClearDark) ||
                            (!props.isClear && props.theme === 'dark'),
                          'text-neutral-white hover:text-neutral-light-gray md:text-neutral-dark-gray md:hover:text-neutral-black':
                            props.isClear && props.isClearDark,
                          'text-neutral-dark-gray hover:text-neutral-black':
                            !props.isClear && props.theme === 'light',
                        }
                      )}
                      href={`${props.discoveryUrl}${t('paths.faq')}`}
                      onClick={handleNavigationLinkClick}
                    >
                      <span>{t('titles.faq')}</span>
                    </CustomLink>
                  </li>
                )}
                {props.quizUrl && (
                  <li className="hidden pis-8 xl:block">
                    <AButton
                      type="a"
                      href={props.quizUrl}
                      target={props.quizUrl !== '/' ? '_blank' : '_self'}
                      rel="noreferrer"
                      style="outline"
                      color={
                        props.isClear || props.theme === 'dark'
                          ? 'white'
                          : 'gray'
                      }
                      onClick={handleQuizButtonClick}
                    >
                      <span>{t('titles.take-our-quiz')}</span>
                    </AButton>
                  </li>
                )}
              </>
            )}
            {props.doctorsUrl && (
              <li className={`${!siteInfo.isUS ? 'hidden' : ''} md:pis-8 md:block`}>
                <AButton
                  type="a"
                  href={props.doctorsUrl}
                  target={props.doctorsUrl !== '/' ? '_blank' : '_self'}
                  rel="noreferrer"
                  style={'solid'}
                  color={'teal'}
                  icon={SvgSearchIcon}
                  iconClass="stroke-current w-6 h-6"
                  onClick={handleDoctorsButtonClick}
                  className={'text-[9px] md:text-[13px] !rounded-[15px] !py-[3px] md:!py-[6px] !px-[10px] md:!px-[22px] md:!rounded-[20px]'}
                >
                  <span>{t('titles.find-a-doctor')}</span>
                </AButton>
              </li>
            )}
            <li className="mis-2 md:mis-6 xl:hidden">
              <button
                className={classNames(
                  'rounded-full p-[6px] inline-flex items-center justify-center uppercase transition-all duration-300 align-middle border-2',
                  {
                    'border-neutral-white text-neutral-white hover:bg-neutral-white hover:text-neutral-dark-gray':
                      (props.isClear && !props.isClearDark) ||
                      (!props.isClear && props.theme === 'dark'),
                    'border-neutral-white text-neutral-white hover:bg-neutral-white hover:text-neutral-dark-gray md:border-neutral-dark-gray md:text-neutral-dark-gray md:hover:bg-neutral-dark-gray md:hover:text-neutral-white':
                      props.isClear && props.isClearDark,
                    'border-primary-teal text-primary-teal hover:bg-primary-teal hover:text-neutral-white':
                      !props.isClear && props.theme === 'light',
                  }
                )}
                onClick={openMenu}
              >
                <ASvg
                  className="fill-current w-6 h-6"
                  svg={SvgHamburgerIcon}
                  aria-label={t('hamburger')}
                />
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <CTheMenu
        menuRef={menuRef}
        isOpen={isOpenMenu}
        brand={props.brand}
        iclBaseUrl={props.backendUrl}
        doctorsUrl={props.doctorsUrl}
        discoveryUrl={props.discoveryUrl}
        quizUrl={props.quizUrl}
        onClose={closeMenu}
        onOpenLocaleSwitcher={handleLocaleButtonClick}
        fullDomain={props.fullDomain}
      />
      {props.hasProgressBar && (
        <div className="w-full c-the-header__progress-bar" aria-hidden="true">
          <div
            className="w-full h-full c-the-header__progress-bar-bar bg-primary-teal"
            style={{
              transform: `scaleX(${pageScrollProgress})`,
            }}
          />
        </div>
      )}
    </header>
  )
}

CTheHeader.defaultProps = defaultProps

export { CTheHeader }
