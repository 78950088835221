import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import SvgPlayIcon from 'ui/svg/icons/stroke/play.svg'
import Glide from '@glidejs/glide'
import { throttle } from 'lodash'
import { AVideo, AVideoMethods } from '../atoms/a-video'
import { ASvg } from '../atoms/a-svg'
import { MPaginationDots } from '../molecules/m-pagination-dots'
import './c-testimonials-slider.css'
import { CTestimonialsUs } from './c-testimonials-us'
import { useSiteInfo } from 'shared'
import TagManager from 'react-gtm-module'

type Testimonial = {
  id?: string
  title?: string
  testimonialOccupation?: string
  testimonialQuote?: string
  testimonialImage?: Array<CraftAssetField>
  testimonialVideo?: Array<CraftEmbeddedAssetField>
  testimonialVideoOrientation?: string
  testimonialsImagePosition?: string
  name?: string
  slug?: string
}

export type CTestimonialsSliderBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  testimonials?: Array<Testimonial>
  disableBordersOnMobile?: boolean
}

type Props = {
  field: Array<CTestimonialsSliderBlock>
}

export const CTestimonialsSlider = ({ field }: Props) => {
  const [isMobile, setIsMobile] = useState(false)
  const mobileBreakpoint = 1024
  const siteInfo = useSiteInfo()
  const isUS = siteInfo.isUS

  const sliderRef = useRef<HTMLDivElement>(null)
  const testimonialMediaRef = useRef<
    Array<AVideoMethods | HTMLImageElement | null>
  >([])
  const glideRef = useRef<Glide | null>(null)

  const [sliderIndex, setSliderIndex] = useState<number>(0)
  const [testimonialMediaPlayed, setTestimonialMediaPlayed] = useState<
    Array<boolean>
  >([])
  const [windowWidth, setWindowWidth] = useState<number>(0)

  const content = useMemo(() => {
    return field && field.length
      ? {
          ...field[0],
          testimonials: [
            ...(field[0]?.testimonials || []).map((testimonial) => ({
              video: testimonial.testimonialVideo,
              image: testimonial.testimonialImage,
              quote: testimonial.testimonialQuote,
              caption: `${testimonial.title} | ${testimonial.testimonialOccupation}`,
              videoOrientation: testimonial.testimonialVideoOrientation,
              imagePosition: testimonial.testimonialsImagePosition,
              name: testimonial.title,
              slug: testimonial.slug,
            })),
          ],
        }
      : null
  }, [field])

  const handlePaginationChangePage = (index: number) => {
    if (glideRef.current) {
      glideRef.current.go(`=${index - 1}`)
    }
  }

  const playTestimonialMedia = (testimonialIndex: number) => {
    setTestimonialMediaPlayed(
      testimonialMediaPlayed.map((item, i) => {
        return testimonialIndex === i ? true : item
      })
    )

    if (testimonialMediaRef.current) {
      const video = testimonialMediaRef.current[
        testimonialIndex
      ] as AVideoMethods
      video.play()
    }
  }

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
    setIsMobile(window.innerWidth < mobileBreakpoint)
  }

  const handleWindowResizeThrottled = throttle(handleWindowResize, 100)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    if (content?.testimonials?.length) {
      setTestimonialMediaPlayed(
        Array(testimonialMediaRef.current.length).fill(false)
      )

      const slider = new Glide(sliderRef.current ?? '', {
        direction: siteInfo.dir as 'ltr' | 'rtl' | undefined,
        gap: 0,
        rewind: false,
        peek: isUS ? (isMobile ? 0 : 130) : 0,
      })

      slider.mount()
      slider.on('run', () => {
        setSliderIndex(slider?.index ?? 0)
        testimonialMediaRef.current?.forEach((component) => {
          const video = component as AVideoMethods
          video.pause()
        })
      })

      glideRef.current = slider

      window.addEventListener('resize', handleWindowResizeThrottled)
      handleWindowResize()
    }

    return () => {
      if (content && glideRef.current) {
        glideRef.current.destroy()
      }
      window.removeEventListener('resize', handleWindowResizeThrottled)
    }
  }, [isMobile])

  if (!content?.testimonials.length) {
    return <></>
  }

  if (isUS) {
    return (
      <CTestimonialsUs
        content={content}
        sliderRef={sliderRef}
        testimonialMediaPlayed={testimonialMediaPlayed}
        playTestimonialMedia={playTestimonialMedia}
        testimonialMediaRef={testimonialMediaRef}
        windowWidth={windowWidth}
        sliderIndex={sliderIndex}
        handlePaginationChangePage={handlePaginationChangePage}
      />
    )
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <section
      id={content?.idName}
      className="mx-3 mb-20 mt-14 c-testimonials-slider md:mx-6 lg:my-6"
    >
      {!!content?.testimonials.length && (
        <div className="relative flex flex-col justify-center mx-auto c-testimonials-slider__container lg:pt-16 lg:pb-24">
          {content.heading && (
            <h2
              className="font-normal text-h5 text-neutral-dark-gray c-testimonials-slider__heading whitespace-pre-line"
              dangerouslySetInnerHTML={{ __html: content.heading ?? '' }}
            />
          )}
          <div ref={sliderRef} className="mt-8 lg:mt-12 glide">
            <div className="glide__track" data-glide-el="track">
              <div className="flex glide__slides">
                {content.testimonials.map((testimonial, testimonialIndex) => {
                  return (
                    <section
                      key={testimonial.caption}
                      className="flex flex-col h-auto glide__slide c-testimonials-slider__testimonial lg:flex-row lg:flex-wrap lg:items-center"
                    >
                      <div className="lg:w-1/2 relative">
                        {!!(testimonial.video && testimonial.video.length) && (
                          <div
                            className={classNames('absolute inset-0 z-10', {
                              hidden: testimonialMediaPlayed[testimonialIndex],
                            })}
                          >
                            <AVideo
                              className="block w-full c-testimonials-slider__testimonial-video h-full"
                              playsInline
                              controls={false}
                              src={
                                (testimonial.video[0].embeddedAsset || {}).url
                              }
                              aria-hidden="true"
                            />
                            <button
                              className="c-testimonials-slider__testimonial-cover w-full h-full absolute inset-0 flex items-center justify-center"
                              aria-label={`Play Video: ${testimonial.caption}`}
                              onClick={() => {
                                playTestimonialMedia(testimonialIndex)
                              }}
                            >
                              <div className="w-12 h-12 text-neutral-dark-gray hover:text-neutral-white bg-neutral-white rounded-full flex items-center justify-center hover:bg-primary-teal transition-colors duration-300 ease-out">
                                <ASvg
                                  className="stroke-current fill-current w-6 h-6"
                                  svg={SvgPlayIcon}
                                  ariaLabel="Play"
                                />
                              </div>
                            </button>
                          </div>
                        )}
                        {!!(testimonial.video && testimonial.video.length) && (
                          <AVideo
                            ref={(el) => {
                              testimonialMediaRef.current[testimonialIndex] =
                                el as AVideoMethods
                            }}
                            className="block w-full c-testimonials-slider__testimonial-video"
                            playsInline
                            controls={true}
                            src={(testimonial.video[0].embeddedAsset || {}).url}
                          />
                        )}
                        {!(testimonial.video && testimonial.video.length) && (
                          <img
                            ref={(el) => {
                              testimonialMediaRef.current[testimonialIndex] = el
                            }}
                            className="block w-full c-testimonials-slider__testimonial-image"
                            src={
                              testimonial.image?.length
                                ? testimonial.image[0].url
                                : ''
                            }
                            alt={
                              testimonial.image?.length
                                ? testimonial.image[0].title
                                : ''
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col items-center flex-grow p-6 mt-3 md:mt-6 lg:mt-0 lg:w-1/2 lg:py-0 bg-secondary-orange lg:bg-transparent c-testimonials-slider__testimonial-body">
                        <div className="w-full c-testimonials-slider__testimonial-content">
                          <blockquote className="font-normal text-p-lg sm:text-blockquote c-testimonials-slider__testimonial-blockquote text-neutral-white">
                            {testimonial.quote}
                          </blockquote>
                          <p className="mt-4 c-testimonials-slider__testimonial-attribution">
                            {testimonial.caption}
                          </p>
                        </div>
                      </div>
                    </section>
                  )
                })}
              </div>
              <div className="px-6 pb-4 -mt-2 lg:p-0 lg:mt-2 bg-secondary-orange lg:bg-transparent">
                <MPaginationDots
                  className="w-full mx-auto lg:mx-0 c-testimonials-slider__pagination-dots"
                  theme={windowWidth < 1024 ? 'secondary-orange' : ''}
                  totalPages={content.testimonials.length}
                  currentPage={sliderIndex + 1}
                  onChangePage={handlePaginationChangePage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export const CTestimonialsSliderFragments = {
  field: `
    fragment testimonialsSlider on testimonialsSlider_MatrixField {
      ... on testimonialsSlider_block_BlockType {
        id
        typeHandle
        idName
        heading
        disableBordersOnMobile
        testimonials {
          id
          title
          slug
          ... on testimonials_testimonials_Entry {
            testimonialOccupation
            testimonialQuote
            testimonialImage {
              url
              title
            }
            testimonialVideo {
              url
              title
              embeddedAsset {
                url
                title
              }
            }
            testimonialVideoOrientation
            testimonialsImagePosition
          }
          ... on testimonialPages_testimonialPages_Entry {
            testimonialOccupation
            testimonialQuote
            testimonialImage {
              url
              title
            }
            testimonialVideo {
              url
              title
              embeddedAsset {
                url
                title
              }
            }
            testimonialVideoOrientation
            testimonialsImagePosition
          }
        }
      }
    }
  `,
}
