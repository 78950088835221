import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import css from 'styled-jsx/css'
import { throttle } from 'lodash'
import { ASvg } from 'ui'
import SvgLogo from 'ui/svg/logos/evo-visian-icl/evo-icl-linear-black.svg'
import Image from 'next/image'
import { AssetInterface } from '../../graphql/global-fragments'

interface CHeaderProps {
  logoImage?: {
    clearLogo: Array<AssetInterface>
    darkLogo: Array<AssetInterface>
  }
  isStella?: boolean
}
export const CHeader = ({ logoImage, isStella }: CHeaderProps) => {
  const router = useRouter()
  const [isClear, setIsClear] = useState<boolean>(true)
  const [lastSection, setLastSection] = useState<string | null>(null)
  const [customLogoClear] = logoImage?.clearLogo ?? []
  const [customLogoDark] = logoImage?.darkLogo ?? []

  const handleWindowScroll = () => {
    setIsClear(window.scrollY <= 50)
    const sections = ['#accuject', '#overview', '#results']

    let last = null

    sections.forEach((section) => {
      const element = document.querySelector(section)
      if (element) {
        const top = element.getBoundingClientRect().top - 95
        if (top <= 0) {
          last = section
        }
      }
    })

    setLastSection(last)
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScrollThrottled)

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
    }
  }, [])

  return (
    <header
      className={classNames(
        'c-header h-[60px] lg:h-[95px] w-full flex items-center justify-between px-6 fixed top-0 left-0 z-60 transition-all duration-200 ease-out',
        {
          'bg-white text-neutral-dark-gray': !isClear,
          'text-white': isClear,
        }
      )}
    >
      <a href="https://staar.com" className="py-4">
        {logoImage ? (
          <>
            <Image
              className={classNames('h-[36px] md:h-[56px] w-auto', {
                hidden: !isClear,
              })}
              src={customLogoClear.url}
              alt={customLogoClear.title}
              width={213}
              height={62}
            />
            <Image
              className={classNames('h-[36px] md:h-[56px] w-auto', {
                hidden: isClear,
              })}
              src={customLogoDark.url}
              alt={customLogoDark.title}
              width={213}
              height={62}
            />
          </>
        ) : (
          <ASvg className="fill-current h-[36px] md:h-[56px]" svg={SvgLogo} />
        )}
      </a>
      <nav className="items-center hidden lg:flex">
        {isStella ? (
          <>
            <Link href="/" passHref legacyBehavior>
              <a
                className={classNames('c-header__nav-item', {
                  active: lastSection === '/',
                })}
              >
                EVO ICL
              </a>
            </Link>
          </>
        ) : (
          <>
            <Link href="/#overview" passHref legacyBehavior>
              <a
                className={classNames('c-header__nav-item', {
                  active: lastSection === '#overview',
                })}
              >
                Overview
              </a>
            </Link>
            <Link href="/#results" passHref legacyBehavior>
              <a
                className={classNames('c-header__nav-item', {
                  active: lastSection === '#results',
                })}
              >
                Results
              </a>
            </Link>
          </>
        )}
        <Link href="/stella" passHref legacyBehavior>
          <a
            className={classNames('c-header__nav-item', {
              active: router.asPath === '/stella',
            })}
          >
            Stella
          </a>
        </Link>
        <a href="https://staar.com" className="c-header__nav-item">
          Staar.com
        </a>
        <Link href="/#contact-us" passHref legacyBehavior>
          <a
            className={classNames(
              'rounded-[56px] text-button font-bold border-2 uppercase px-6 py-2 transition-all duration-200 ease-out ml-10',
              {
                'text-white border-white hover:text-neutral-dark-gray hover:bg-white':
                  isClear,
                'text-primary-teal border-primary-teal hover:text-white hover:bg-primary-teal':
                  !isClear,
              }
            )}
          >
            Contact us
          </a>
        </Link>
      </nav>
      <style jsx>{styles}</style>
    </header>
  )
}

const styles = css`
  .c-header__nav-item {
    padding: 1rem 2rem;
    text-transform: uppercase;
    position: relative;
    font-size: 15px;
    letter-spacing: 0.17em;
    line-height: 24px;
  }

  .c-header__nav-item::after {
    position: absolute;
    background-color: currentColor;
    bottom: 0;
    content: '';
    height: 2px;
    left: 50%;
    transform: translateX(-50%) scale(0);
    transform-origin: center;
    transition: transform 0.2s ease-out;
    width: calc(100% - 64px);
  }

  .c-header__nav-item.active::after,
  .c-header__nav-item:hover::after {
    transform: translateX(-50%) scale(1);
  }
`
