import { ASvg } from 'ui'
import SvgStaarEyeLogo from 'ui/svg/logos/evo-visian-icl/staar-eye.svg'
import SvgFacebookIcon from 'ui/svg/icons/social/Facebook.svg'
import SvgInstagramIcon from 'ui/svg/icons/social/Instagram.svg'
import SvgYoutubeIcon from 'ui/svg/icons/social/Youtube.svg'

interface CFooterProps {
  footerSocials?: { socialType: string; socialLink: string }[] | undefined
}
export const CFooter = ({ footerSocials }: CFooterProps) => {
  const facebookCustomLink = footerSocials?.find(
    (social) => social.socialType === 'facebook'
  )?.socialLink
  const instagramCustomLink = footerSocials?.find(
    (social) => social.socialType === 'instagram'
  )?.socialLink
  const youtubeCustomLink = footerSocials?.find(
    (social) => social.socialType === 'youtube'
  )?.socialLink

  return (
    <footer className="c-footer py-9 xl:py-0 xl:h-[105px] bg-neutral-black text-white flex flex-col xl:flex-row xl:items-center px-6">
      <a href="https://staar.com">
        <ASvg className="flex-shrink-0 mr-12" svg={SvgStaarEyeLogo} />
      </a>
      <nav className="flex-shrink-0 flex flex-col xl:flex-row mt-8 xl:mt-0">
        <a
          href="https://staar.com/products"
          className="c-footer__nav-item uppercase text-button font-bold mt-2 xl:mt-0 xl:py-[10px] xl:px-[22px]"
        >
          Products
        </a>
        <a
          href="https://staar.com/our-mission"
          className="c-footer__nav-item uppercase text-button font-bold mt-2 xl:mt-0 xl:py-[10px] xl:px-[22px]"
        >
          Company
        </a>
        <a
          href="https://discoverEVO.com"
          className="c-footer__nav-item uppercase text-button font-bold mt-2 xl:mt-0 xl:py-[10px] xl:px-[22px]"
        >
          Visit discoverEVO.com
        </a>
      </nav>
      <div className="flex-grow text-neutral-gray text-[12px] xl:pl-5 uppercase mt-2 xl:mt-0">
        <span className="hidden xl:inline">
          &copy; {new Date().getFullYear()} STAAR SURGICAL®. ALL RIGHTS
          RESERVED.
        </span>
        <a href="https://staar.com/privacy-policy" className="py-4 xl:p-4">
          Privacy Policy
        </a>
        <span>|</span>
        <a href="https://staar.com/terms-of-use" className="py-4 xl:p-4">
          Terms of Use
        </a>
      </div>
      <div className="flex-shrink-0 flex mt-6 xl:mt-0">
        <a
          href={
            facebookCustomLink ?? 'https://www.facebook.com/staarsurgical.us'
          }
          target="_blank"
          className="c-footer__social-link w-9 h-9 text-[#e5e7eb] opacity-50 hover:opacity-100 transition-opacity duration-200 ease-out"
          rel="noreferrer"
        >
          <ASvg className="w-6 h-6 fill-current" svg={SvgFacebookIcon} />
        </a>
        <a
          href={
            instagramCustomLink ?? 'https://www.instagram.com/staarsurgical/'
          }
          target="_blank"
          className="c-footer__social-link w-9 h-9 text-[#e5e7eb] opacity-50 hover:opacity-100 transition-opacity duration-200 ease-out"
          rel="noreferrer"
        >
          <ASvg className="w-6 h-6 fill-current" svg={SvgInstagramIcon} />
        </a>
        <a
          href={
            youtubeCustomLink ??
            'https://www.youtube.com/channel/UCeIQskXc99nML8y3C7F9pOQ'
          }
          target="_blank"
          className="c-footer__social-link w-9 h-9 text-[#e5e7eb] opacity-50 hover:opacity-100 transition-opacity duration-200 ease-out"
          rel="noreferrer"
        >
          <ASvg className="w-6 h-6 fill-current" svg={SvgYoutubeIcon} />
        </a>
      </div>
      <div className="xl:hidden text-neutral-gray text-[12px] uppercase mt-10">
        &copy; {new Date().getFullYear()} STAAR SURGICAL®. ALL RIGHTS RESERVED.
      </div>
    </footer>
  )
}
