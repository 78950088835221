import classNames from 'classnames'
import React, { MutableRefObject, RefObject, useState } from 'react'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { atom, useAtom } from 'jotai'
import { AVideo, AVideoMethods } from '../atoms/a-video'
import { MPaginationDots } from '../molecules/m-pagination-dots'
import './c-testimonials-slider-us.css'
import { MVideoThumbnail } from '../molecules/m-video-thumbnail'
import Image from 'next/image'
import { MImageWrapper } from '../molecules/m-image-wrapper'
import Link from 'next/link'
import { MSliderArrows } from '../molecules/m-slider-arrows'
import { MModal } from '../molecules/m-modal'
import { MVideoModal } from '../molecules/m-video-modal'
import { requsetCallbackModalAtom } from 'doctors/components/organisms/c-request-callback-modal'
import TagManager from 'react-gtm-module'

type Props = {
  content: {
    testimonials: {
      video: CraftEmbeddedAssetField[] | undefined
      image: CraftAssetField[] | undefined
      quote: string | undefined
      caption: string
      videoOrientation: string | undefined
      imagePosition: string | undefined
      name?: string
      slug?: string
    }[]
    id?: string | undefined
    typeHandle?: string | undefined
    idName?: string | undefined
    heading?: string | undefined
    disableBordersOnMobile?: boolean
  }
  sliderRef: RefObject<HTMLDivElement>
  testimonialMediaPlayed: boolean[]
  playTestimonialMedia: (testimonialIndex: number) => void
  testimonialMediaRef: MutableRefObject<
    (HTMLImageElement | AVideoMethods | null)[]
  >
  windowWidth: number
  sliderIndex: number
  handlePaginationChangePage: (index: number) => void
}

export const videoModalAtom = atom<string | undefined>(undefined)

export const CTestimonialsUs = ({
  content,
  sliderRef,
  testimonialMediaPlayed,
  playTestimonialMedia,
  testimonialMediaRef,
  windowWidth,
  sliderIndex,
  handlePaginationChangePage,
}: Props) => {
  const [videoModal, setVideoModal] = useAtom(videoModalAtom)

  return (
    <section
      id={content?.idName}
      className={classNames('c-testimonials-slider-us', {
        'c-testimonials-slider-us__disable-border-mobile':
          content?.disableBordersOnMobile,
      })}
    >
      <MVideoModal
        video={videoModal}
        open={Boolean(videoModal)}
        onClose={() => setVideoModal(undefined)}
      />
      {!!content?.testimonials.length && (
        <div className="relative flex flex-col justify-center mx-auto c-testimonials-slider-us__container relative">
          {content.heading && (
            <h2
              className="font-normal text-3xl md:text-heading c-testimonials-slider-us__heading text-center px-[24px] mb-4"
              dangerouslySetInnerHTML={{ __html: content.heading ?? '' }}
            />
          )}
          <div ref={sliderRef} className="glide">
            <div className="glide__track" data-glide-el="track">
              <div className="flex glide__slides md:px-4">
                {content.testimonials.map((testimonial, testimonialIndex) => {
                  const videoSize =
                    testimonial.videoOrientation === 'vertical'
                      ? 'aspect-[3/4] md:w-[400px]'
                      : 'md:aspect-video'

                  return (
                    <section
                      key={testimonial.caption}
                      className="flex flex-col h-auto glide__slide c-testimonials-slider-us__testimonial lg:flex-row lg:items-start justify-center md:py-5 pb-5 gap-[40px] md:gap-[50px] px-[34px] md:px-0"
                    >
                      <p className="c-testimonials-slider-us__testimonial-attribution text-xl text-center md:hidden">
                        {testimonial.caption}
                      </p>
                      <div className="relative">
                        <MImageWrapper
                          className={`c-hero__image-wrapper flex-1 ${videoSize} max-w-[85%] mx-auto`}
                          type="accent"
                        >
                          {!!(testimonial.video && testimonial.video.length) ? (
                            <MVideoThumbnail
                              className={`${videoSize} max-w-[100%] h-full`}
                              vidRef={(el: any) => {
                                testimonialMediaRef.current[testimonialIndex] =
                                  el as AVideoMethods
                              }}
                              video={testimonial.video}
                              thumbnail={testimonial.image?.[0].url}
                              playTop
                              position={testimonial.imagePosition}
                              onClick={(videoUrl: string | undefined) => {
                                setVideoModal(videoUrl)
                              }}
                            />
                          ) : (
                            <Image
                              src={testimonial.image?.[0].url ?? ''}
                              alt={testimonial.image?.[0].title ?? ''}
                              width={700}
                              height={900}
                            />
                          )}
                        </MImageWrapper>
                      </div>
                      <div className="flex flex-col items-start md:px-6 mt-3 md:mt-6 lg:mt-0 c-testimonials-slider-us__testimonial-body lg:max-w-[45%] self-stretch">
                        <blockquote className="font-light text-p-lg sm:text-3xl c-testimonials-slider-us__testimonial-blockquote">
                          <span>“</span>
                          {testimonial.quote}
                          <span>”</span>
                        </blockquote>
                        <p className="pt-6 c-testimonials-slider-us__testimonial-attribution text-xl font-semibold hidden md:block px-[20px] mt-auto">
                          {testimonial.caption}
                        </p>
                        <Link
                          className="mt-6 mb-2 underline font-semibold text-primary-dark-teal text-xl block px-[20px]"
                          href={`/testimonials/${testimonial.slug}`}
                        >
                          Read Why {testimonial.name} Got EVO ICLs
                        </Link>
                      </div>
                    </section>
                  )
                })}
              </div>
              <MSliderArrows
                className="absolute left-[20px] right-[20px] md:left-[124px] md:right-[124px] bottom-[unset] top-[43%] flex justify-between items-center"
                totalPages={content.testimonials.length}
                currentPage={sliderIndex + 1}
                onChangePage={handlePaginationChangePage}
              />
              <div className="px-6 pb-4 mt-2 lg:p-0 lg:mt-[50px]">
                <MPaginationDots
                  className="w-full mx-auto lg:mx-0 c-testimonials-slider-us__pagination-dots justify-center"
                  totalPages={content.testimonials.length}
                  currentPage={sliderIndex + 1}
                  onChangePage={handlePaginationChangePage}
                  dotsOnly
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
